<template>
  <v-skeleton-loader
    type="image"
    v-if="!value"
  />
  <v-card v-else>
    <v-card-title>{{$t('t.EmailSettings')}}</v-card-title>
    <v-card-text>
      <v-text-field
        autocomplete="no"
        class="email"
        :label="$t('t.Email')"
        v-model="computedEmail"
        :rules="emailRules"
        :clearable="true"
      />
      <picker
        document-types="providers"
        :clearable="true"
        :label="$t('t.OutgoingEmailProvider')"
        :selected-id.sync="computedOutgoingEmailProviderId"
        :dense="false"
        :disabled="!isCompany"
        :can-send-email="true"
      />
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  components: {
    Picker: () => import('@/components/picker')
  },
  data () {
    return {
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      dataEmail: null,
      dataOutgoingEmailProviderId: null
    }
  },
  methods: {
    emitDocument () {
      const doc = {
        email: this.dataEmail,
        outgoingEmailProviderId: this.dataOutgoingEmailProviderId
      }

      if (!this.lodash.isEqual(this.value, doc)) {
        this.$emit('input', this.lodash.cloneDeep(doc))
      }
    }
  },
  computed: {
    emailRules () {
      const rules = [
        v => (v ?? '').length <= 500
      ]

      return rules
    },
    computedEmail: {
      get () {
        return this.dataEmail
      },
      set (v) {
        this.dataEmail = v
        this.dataDocumentEmit()
      }
    },
    computedOutgoingEmailProviderId: {
      get () {
        return this.dataOutgoingEmailProviderId
      },
      set (v) {
        this.dataOutgoingEmailProviderId = v
        this.dataDocumentEmit()
      }
    }
  },
  props: {
    value: Object,
    isCompany: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      handler (v) {
        this.dataEmail = v?.email
        this.dataOutgoingEmailProviderId = v?.outgoingEmailProviderId
      },
      immediate: true
    },
    isCompany: {
      handler (v) {
        if (!v) {
          this.dataOutgoingEmailProviderId = null
          this.dataDocumentEmit()
        }
      },
      immediate: true
    }
  }
}
</script>
<style lang="stylus" scoped>
.v-card__text
  display flex
  flex-direction row
  flex-wrap wrap

.v-text-field
  margin-right 1em

.email
  max-width 500px
</style>
